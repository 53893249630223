import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../index";
import './styles/style.css'
import {Space, Switch} from "antd";
import {AlertIcon} from "../icons/ALert";
import AlertService from "../../services/Alert-service";
import {click} from "../../hooks/useTelegram";

export const EditServiceStatus = () => {
    const { store } = useContext(Context)
    const [checked, setChecked] = useState<boolean>(false);

    const changeServiceStatus = async () => {
        await store.editServiceStatus()
        await getServiceStatus()
        click()
    }
    const getServiceStatus = async () => {
        const status = await store.getServiceStatus()
        console.log(status)
        // @ts-ignore
        setChecked(status)
    }
    const serviceALert = async () => {
        const response = await AlertService.serviceAlert()
        click()
    }

    useEffect(() => {
        getServiceStatus()
    }, [checked])

    return (
        <>
            <Space.Compact style={{ height: "50px", marginTop: 10, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div className="editservicestatus">
                    <p className='editservicestatus_header'>Сервис статус <span style={{ marginLeft: 5 }}>⚙️🤖</span></p>
                    <Switch checked={checked} onChange={changeServiceStatus}/>
                </div>
                <div className="alertservice" onClick={serviceALert}>
                    <AlertIcon size={"30px"} fill={"#fff"} parentElem={"alertservice"}/>
                </div>
            </Space.Compact>
        </>
    )
}

import lottie from 'lottie-web'
import { defineElement } from 'lord-icon-element'
import { useState } from "react";

interface Props {
    size: string,
    fill: string
}

export const InfoIcon = ({ size, fill }: Props) => {

    defineElement(lottie.loadAnimation)

    return (
        <>
            <div className="infoicon">
                {/* @ts-ignore*/}
                <lord-icon style={{ width: `${size}`, height: `${size}` }} colors={`primary:${fill}`} trigger="click" target=".aboutTariff" src="https://cdn.lordicon.com/ncxoarcp.json"></lord-icon>
            </div>
        </>
    )
}
import axios, { AxiosResponse } from "axios";
import { BackendUrl } from "../config/config";


export default class BotSettingsService {
    static async editServiceStatus() {
        try {
            return await axios.post(`${BackendUrl}/editservicestatus`)
        } catch (e) {
            console.log({ code: 500, message: e, errorPlace: 'BotSettingsService.ts => editServiceStatus()' })
        }
    }
    static async getServiceStatus(): Promise<AxiosResponse<boolean>> {
        try {
            return await axios.post<boolean>(`${BackendUrl}/getservicestatus`)
        } catch (e) {
            console.log({ code: 500, message: e, errorPlace: 'BotSettingsService.ts => editServiceStatus()' })
        }
    }
}
import ProducerService from "../../../../services/Producer-service"
import { UsersIcon, DoneIcon } from "../../../../components/icons";
import React, {FC, useState} from "react";
import {Input, Modal, Space} from "antd";
import './styles/style.css'
import {doneClick} from "../../../../hooks/useTelegram";

interface Props {
    open: boolean,
    setOpen: (value: boolean) => void,
}

export const CreateProducerModal: FC<Props> = ({ open, setOpen }) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [username, setUsername] = useState('');
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setUsername(value);
        // Regular expression to check if the username matches the format of a valid Telegram username
        const usernameRegex = /^@[A-Za-z0-9_]{5,31}$/;
        const isUsernameValid = usernameRegex.test(value);
        setIsValid(isUsernameValid);
    };
    const handleOk = async () => {
        if (!isValid || username === '' || !username) return alert("Неверный формат юзернейма 🚫")
        setConfirmLoading(true);
        const response = await ProducerService.createProducer(username.replace(/@/g, ""));
        console.log(response.data)
        if (response?.data?.code === 405) {
            alert(response.data?.message);
            setOpen(false);
            setConfirmLoading(false);
            return setUsername("")
        }
        doneClick()
        alert(`Продюсер ${username} успешно создан! 🎉`)
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
            setUsername("")
        }, 1000);
    };
    const handleCancel = () => {
        setOpen(false);
        setUsername("")
    };

    return (
        <>
        <Modal cancelText="Отмена" okText={"+ Добавить"} className="addBlogerModal" closable={false} open={open} onOk={handleOk} confirmLoading={confirmLoading} onCancel={handleCancel}>
            {/*Header*/}
            <Space.Compact className="addBlogerModal_header">
                <UsersIcon size={"24px"} fill={"#1D76EE"}/>
                <p className="header_p">Добавить блогера</p>
            </Space.Compact>
            {/*Форма*/}
            <Input className="addBlogerModal_input" value={username} onChange={handleInputChange} type="text" placeholder="Введите @username вместе с @"/>
            {username.length > 0 && (
                <span className="validation_span" style={{ color: isValid ? '#69b919' : 'red' }}>
                    {isValid ? <><span>@username валиден</span><DoneIcon size={"15px"} fill={"#69b919"} parrentElem={"validation_span"}/></> : 'Невалидный @username 🚫'}
                </span>
            )}
        </Modal>
        </>
    )
}
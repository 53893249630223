import axios, {AxiosResponse} from "axios";
import {IExtraStatusProps, IInvoice, IUser} from "../types/types";


export default class StatisticService {
    static async getDailyStats() {
        try {
            return await axios.post("https://ai-in-telegram-control-panel-bot-backend.ru/app1/stats/dailystats")
        } catch (error) {
            console.error({ code: 500, message: 'Internal Server Error', errorPlace: 'StatisticService.getDailyStats' });
        }
    }
    static async getInvoiceStats() {
        try {
            return await axios.post(`https://www.ai-in-telegram-control-panel-bot-backend.ru/app1/stats/invoicestats`)
        } catch (e) {
            console.log({ code: 500, message: e.message, errorPlace: 'StatsService.ts => getStats()' })
        }
    }
    static async getInvoices(): Promise<AxiosResponse<IInvoice[]>> {
        try {
            return await axios.post<IInvoice[]>(`https://www.ai-in-telegram-control-panel-bot-backend.ru/app1/stats/invoices`)
        } catch (e) {
            console.log({ code: 500, message: e.message, errorPlace: 'StatsService.ts => getStats()' })
        }
    }
    static async getUserInfoStats(username: string, userId: number) {
        try {
            return await axios.post(`https://www.ai-in-telegram-control-panel-bot-backend.ru/app1/stats/userstats`, { username, userId })
        } catch (e) {
            console.log({ code: 500, message: e.message, errorPlace: 'StatsService.ts => getStats()' })
        }
    }
    static async getUsersConversionToActive(): Promise<AxiosResponse<IExtraStatusProps[]>> {
        try {
            return await axios.post<IExtraStatusProps[]>(`https://www.ai-in-telegram-control-panel-bot-backend.ru/app1/stats/users`)
        } catch (e) {
            console.log({ code: 500, message: e.message, errorPlace: 'StatsService.ts => getStats()' })
        }
    }
}

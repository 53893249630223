import {ArrowRightOutlined} from "@ant-design/icons";
import {StatsIcon} from "../icons";
import './styles/style.css'
import {useNavigate} from "react-router-dom";

export const Revenue = () => {
    const navigate = useNavigate()

    return (
        <>
            <div className="revenue_98283287" onClick={() => navigate("/statistics")}>
                <div className="revenue_header">
                    <StatsIcon fill={"#fff"} size={"22px"} parrentElem={"revenue_header"}/>
                    <div className="revenue_header_h1">Аналитика</div>
                    <ArrowRightOutlined/>
                </div>
            </div>
        </>
    )
}
import StatisticsPage from "./pages/Statistics";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Ads from "./pages/Ads";
import React from 'react';
import './styles/app.css';

function App() {

  return (
      <>
          <Routes>
              <Route path="/" element={<Dashboard/>}/>
              <Route path="/ads" element={<Ads/>}/>
              <Route path="/statistics" element={<StatisticsPage/>}/>
          </Routes>
      </>
  );
}

export default App;

import ApiKeyService from "../../services/ApiKeys-service";
import React, {FC, useEffect, useState} from "react";
import {Widget} from "../widget/widget";
import './styles/style.css'

interface Props {

}
export const ApiKeysCountWidget: FC<Props> = () => {
    const [apiKeysCount, setApiKeysCount] = useState<number>(0)
    const getApiKeysCount = async () => {
        const response = await ApiKeyService.getApiKeysCount()
        if (response?.data?.status === 403) return alert("Не удалось получить кол-во API ключей")
        const count = response?.data?.count
        return setApiKeysCount(count)
    }

    useEffect(() => {
        getApiKeysCount()
    }, [])

    return (
        <>
            <Widget title={"Кол-во рабочих API ключей"} stats={apiKeysCount} />
        </>
    )
}

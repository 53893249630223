import {EditServiceStatus} from "../../components/EditServiceStatus/editServiceStatus";
import { AllUsersCountWidget } from "../../components/allUsersCount/allUsersCount";
import { UploadApiKeysWidget } from "../../components/uploadApiKeys/uploadApiKeys";
import { ApiKeysCountWidget } from "../../components/apiKeysCount/apiKeysCount";
import {CreateProducer} from "../../components/createProducer/createProducer";
import {AccountIcon, InfoIcon} from "../../components/icons";
import { DailyStats, Revenue } from "../../components";
import {ArrowRightOutlined} from "@ant-design/icons";
import React, {useContext, useState} from "react";
import {click} from "../../hooks/useTelegram";
import { Context } from "../../index";
import {UserStats} from "./modals";
import './styles/style.css'

const Dashboard = () => {
    const { store } = useContext(Context)
    const usernames = ['fullstackdevpitt', 'Web3_Guardian', 'Zen_Evgen', 'camillasence']
    const [userStatsModal, setUserStatsModal] = useState(false)

    if (!usernames.includes(store.username)) {
        return <></>
    }
    
    return (
        <>
            <UserStats open={userStatsModal} setOpen={setUserStatsModal}/>
            <div className="dashboard">
                <header className="dashboard_header">
                    <AccountIcon size={"30px"} fill={"#fff"}/>
                    <div className="username">@{store.username}</div>
                </header>
                <div className="dashboard_content">
                    <div className="content_widgets">
                        <AllUsersCountWidget/>
                        <ApiKeysCountWidget/>
                    </div>
                    <Revenue/>
                    <EditServiceStatus/>
                    <CreateProducer/>
                    {/*<div className="adsWidget" onClick={() => { navigate("/ads"); click() }}>*/}
                    {/*    <AlertIcon size={"24px"} fill={"#fff"} parentElem={"adsWidget"}/>*/}
                    {/*    <h1 className="adsWidget_h1">Рекламные ордера</h1>*/}
                    {/*    <ArrowRightOutlined/>*/}
                    {/*</div>*/}
                    <div className="getUserStats" onClick={() => { click(); setUserStatsModal(true) }}>
                        <InfoIcon size={"24px"} fill={"#fff"}/>
                        <h1 className="getUserStats_h1">Статистика по пользователю</h1>
                        <ArrowRightOutlined style={{ marginLeft: "auto" }}/>
                    </div>
                    <DailyStats/>
                    <UploadApiKeysWidget/>
                </div>
            </div>
        </>
    )
}

export default Dashboard
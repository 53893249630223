import StatisticService from "../../../../services/Statistic-service";
import ProducerService from "../../../../services/Producer-service";
import {DoneIcon, UsersIcon} from "../../../../components/icons";
import React, {useContext, useEffect, useState} from "react";
import {doneClick, tg} from "../../../../hooks/useTelegram";
import {IUser} from "../../../../types/types";
import {Context} from "../../../../index";
import {Input, Modal, Space} from "antd";
import './styles/style.css'


export const UserStats = ({ open, setOpen }) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [username, setUsername] = useState('');
    const [user, setUser] = useState<IUser>();
    const { store } = useContext(Context)

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setUsername(value);
        // Regular expression to check if the username matches the format of a valid Telegram username
        const usernameRegex = /^@[A-Za-z0-9_]{5,31}$/;
        const isUsernameValid = usernameRegex.test(value);
        setIsValid(isUsernameValid);
    };

    const handleOk = async () => {
        if (!isValid || username === '' || !username) return alert("Неверный формат юзернейма 🚫")
        setConfirmLoading(true);
        const response = await StatisticService.getUserInfoStats(username.replace(/@/g, ""), user?.userId)
        console.log(response.data)
        if (response?.data?.code === 405) {
            alert(response.data?.message);
            setOpen(false);
            setConfirmLoading(false);
            return setUsername("")
        }
        doneClick()
        tg.close()
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
            setUsername("")
        }, 1000);
    };

    const handleCancel = () => {
        setOpen(false);
        setUsername("")
    };

    const getUser = async () => {
        const user = await store.getUser()
        setUser(user as IUser)
    }

    useEffect(() => {
        getUser()
    }, [store])

    return (
        <Modal cancelText="Отмена" okText={"Найти"} className="userStatsModal" closable={false} open={open} onOk={handleOk} confirmLoading={confirmLoading} onCancel={handleCancel}>
            {/*Header*/}
            <Space.Compact className="userStatsModal_header">
                <UsersIcon size={"24px"} fill={"#1D76EE"}/>
                <p className="header_p">Найти пользователя</p>
            </Space.Compact>
            {/*Форма*/}
            <Input className="userStatsModal_input" value={username} onChange={handleInputChange} type="text" placeholder="Введите @username вместе с @"/>
            {username.length > 0 && (
                <span className="validation_span" style={{ color: isValid ? '#69b919' : 'red' }}>
                    {isValid ? <><span>@username валиден</span><DoneIcon size={"15px"} fill={"#69b919"} parrentElem={"validation_span"}/></> : 'Невалидный @username 🚫'}
                </span>
            )}
        </Modal>
    )
}
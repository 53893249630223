import { BackendUrl } from "../config/config";
import axios from 'axios'

export default class ApiKeysService {
    static async sendApiKeys(keys: string) {
        try {
            return await axios.post(`${BackendUrl}/uploadapikeys`, { keys })
        } catch (error) {
            const err = { status: 500, error: error, errorplace: "ApiKeys-service.ts => sendApiKeys()" }
            console.log(err)
        }
    }
    static async getApiKeysCount() {
        try {
            return await axios.post(`${BackendUrl}/getapikeyscount`)
        } catch (error) {
            const err = { status: 500, error: error, errorplace: "ApiKeys-service.ts => getApiKeysCount()" }
            console.log(err)
        }
    }
}
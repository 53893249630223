import axios, { AxiosResponse } from 'axios'

export default class ProducerService {
    static async createProducer(username: string): Promise<AxiosResponse<any>> {
        try {
            return await axios.post(`https://ai-in-telegram-control-panel-bot-backend.ru/app2/producer/createproducer`, { username })
        } catch (error) {
            console.log({ status: 500, error: error.message, errorplace: "Producer-service.ts => createProducer()" })
        }
    }
}
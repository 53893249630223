import { Column } from '@ant-design/plots'
import "./styles/style.css"
import {InfoIcon} from "../icons";
import React, {useEffect, useState} from "react";
import StatisticService from "../../services/Statistic-service";
import {Empty} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";

interface Props {
    date: string,
    count: number
}

export const DailyStats = () => {
    const [dailyStats, setDailyStats] = useState<Props[]>([])
    const [dailyStatsData, setDailyStatsData] = useState<any>()
    const [differentPercent, setDifferentPercent] = useState<number>(0)
    const [differentCount, setDifferentCount] = useState<number>(0)
    const config = {
        data: dailyStats,
        xField: 'date',
        yField: 'count',
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 1,
            },
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        meta: {
            date: {
                alias: 'Дата',
            },
            count: {
                alias: 'Кол-во',
            },
        },
    };
    const fetchDailyStats = async () => {
        const response = await StatisticService.getDailyStats()
        setDailyStatsData(response.data)
        setDailyStats(response.data?.weeklyStats)
    }
    const countDifferent = () => {
        if (dailyStatsData?.dailyActiveUsers.length > 0) {
            const lastDayCount = dailyStats[dailyStats?.length - 1].count
            const currentDayCount = dailyStatsData?.dailyActiveUsers.length
            setDifferentCount(currentDayCount - lastDayCount)
            setDifferentPercent((currentDayCount - lastDayCount) / lastDayCount * 100)
        }
    }

    useEffect(() => {
        fetchDailyStats()
    }, [])

    useEffect(() => {
        countDifferent()
    }, [dailyStats])

    return (
        <>
            <div className="dailyActiveUsersToday">
                <div className="dailyActiveUsersToday_header">
                    <InfoIcon size={"24px"} fill={"#fff"}/>
                    Кол-во активных пользователей<br/>за сегодня
                </div>
                <div className="dailyActiveUsersCount">
                    {dailyStatsData?.dailyActiveUsers.length}
                    <div className={differentCount > 0 ? "dailyActiveUsersCount_difference" : "dailyActiveUsersCount_null"}>
                        {differentCount > 0
                            ?
                            <><ArrowUpOutlined/> {differentPercent.toFixed(2) + " %"}</>
                            :
                            <><ArrowDownOutlined/> {differentPercent.toFixed(2)}{" %"}</>}
                    </div>
                </div>
            </div>
            <div className="dailyStats">
                <div className="dailyStats_header">
                    <InfoIcon size={"24px"} fill={"#fff"}/>
                    Кол-во активных пользователей<br/>за 24 часа в течении недели</div>
                {dailyStats.length === 0
                    ?
                    <>
                        <Empty description="Нету данных" image={"https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"}/>
                    </>
                    :
                    <>
                        {/*@ts-ignore*/}
                        <Column {...config} height={200} columnStyle={{ fill: "#1D76EE" }} />
                    </>
                }
            </div>
        </>
    )
}
// npm i @grammyjs/web-app
// npm uninstall @grammyjs/web-app

const tg = window.Telegram.WebApp
const user = tg.initDataUnsafe.user
const queryId = tg.initDataUnsafe?.query_id
const click = () => {
    tg.HapticFeedback.impactOccurred("medium")
}
const doneClick = () => {
    tg.HapticFeedback.notificationOccurred("success")
}

export { tg, user, queryId, click, doneClick }
import React, {FC, ReactNode} from "react";
import './styles/style.css'

interface Props {
    title: string,
    stats: number
}
export const Widget: FC<Props> = ({ title, stats }) => {

    return (
        <>
            <div className="widgetCard">
                <div className="title">{title}</div>
                <div className="stats" style={{ fontSize: "35px" }}>{stats}</div>
            </div>
        </>
    )
}

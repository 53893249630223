import React, { useContext, useState } from "react";
import {doneClick} from "../../hooks/useTelegram";
import {KeyOutlined } from "@ant-design/icons";
// @ts-ignore
import { Keyboard } from 'react-native-web'
import { AirPlaneIcon } from "../icons";
import { Context } from "../../index";
import {Button, Input} from 'antd'
import './styles/style.css'

export const UploadApiKeysWidget = () => {
    const { store } = useContext(Context)
    const [value, setValue] = useState<string>("")
    const [status, setStatus] = useState<boolean>(false)

    const closeKeyboard = async () => {
        Keyboard?.dismiss()
    }
    const sendTokens = async () => {
        if (!value) return alert("Заполните поле")
        const response = await store.sendApiKeys(value)
        if (response?.data?.status === 403) return alert("Непредвиденная ошибка, попробуйте еще раз")
        setValue('')
        setStatus(true)
        console.log("Upload success");
        doneClick()
        return setTimeout(async () => {
            setStatus(false)
            window.location.reload()
        }, 2000)
    }

    return (
        <>
            <div className="uploadApiKeysWidget">
                <h1 className="uploadApiKeysWidget_header">
                    <KeyOutlined/>
                    <span>Загрузить API ключи</span>
                    {value ? <div className='header_closeKeyboardButton' onClick={closeKeyboard}>Готово</div> : <></>}
                </h1>
                <div className="uploadApiKeysForm">
                    <Input className="uploadApiKeysInput" placeholder="..." value={value} onChange={(e) => setValue(e.target.value)}/>
                    <div className="uploadApiKeysButton" onClick={sendTokens} >
                        <AirPlaneIcon size={"26px"} fill={"#000"} status={status} />
                    </div>
                </div>
            </div>
        </>
    )
}

import { defineElement } from 'lord-icon-element'
import lottie from 'lottie-web'
import { useState } from "react";

interface Props {
    size: string,
    onClick: () => void;
}

export const BackArrowIcon = ({ size, onClick  }: Props) => {
    const [isPlaying, setIsPlaying] = useState<boolean>();

    defineElement(lottie.loadAnimation)

    const handleClick = async () => {
        await onClick()
    }

    return (
        <>
            <div className="backarrowicon" onClick={handleClick} style={{ transform: "rotate(180deg)", width: `${size}` }}>
                {/* @ts-ignore*/}
                <lord-icon style={{ width: `${size}`, height: `${size}` }} colors="primary:#1d76ee" trigger="click" target=".backarrowicon" src="https://cdn.lordicon.com/zmkotitn.json"></lord-icon>
            </div>
        </>
    )
}
import {IAds} from "../../types/types";
import {Progress} from "antd";
import "./styles/style.css"
import {FC} from "react";

export const AdsComponent: FC<IAds> = (ads, key: number) => {

    return (
        <>
            <div className="ads_component" key={key}>
                <div className="ads_channel">
                    <span>{ads?.channel}</span>
                    <div className="ads_adsStatus">{ads?.adsStatus}</div>
                </div>
                <div className="ads_component_stats">Выполнено {ads?.subscribersComplete}/{ads?.subscriptionGoal}</div>
                <Progress percent={ads?.subscribersComplete / ads?.subscriptionGoal * 100}/>
            </div>
        </>
    )
}
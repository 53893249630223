import lottie from 'lottie-web'
import { defineElement } from 'lord-icon-element'
import { useState } from "react";

interface Props {
    size: string,
    fill: string
}

export const UsersIcon = ({ size, fill }: Props) => {

    defineElement(lottie.loadAnimation)

    return (
        <>
            <div className="usersicon">
                {/* @ts-ignore*/}
                <lord-icon style={{ width: `${size}`, height: `${size}` }} colors={`primary:${fill}`} trigger="click" target=".statswidget" src="https://cdn.lordicon.com/nkrqhzkh.json"></lord-icon>
            </div>
        </>
    )
}
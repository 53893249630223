interface Props {
    size: string,
    fill: string,
    status: boolean
}

export const AirPlaneIcon = ({ size, fill, status }: Props) => {
    if (status) {
        return (
            <>
                <svg width={size} height={size} fill={fill} strokeWidth="0.2" stroke='#000' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M21.987 2.5a.625.625 0 0 1-.032.357l-3.673 9.18A5.626 5.626 0 0 0 12 17.625c0 .625 0 1.875-.965.45l-1.988-3.123-6.243-3.972-.002-.003-.512-.325a.624.624 0 0 1 .102-1.108l.565-.225h.002l18.183-7.274a.625.625 0 0 1 .845.454Zm-11.69 12.087 9.367-9.367.588-1.473-1.472.589-9.368 9.367.423.27a.607.607 0 0 1 .192.192l.27.422Z" clipRule="evenodd"></path>
                    <path fillRule="evenodd" d="M20.719 20.719a4.375 4.375 0 1 0-6.188-6.188 4.375 4.375 0 0 0 6.188 6.188Zm-1.44-5.275a.624.624 0 0 1 .445.94l-1.669 2.782a.937.937 0 0 1-1.468.18l-.967-.966a.626.626 0 1 1 .885-.885l.684.684 1.462-2.438a.626.626 0 0 1 .628-.297Z" clipRule="evenodd"></path>
                </svg>
            </>
        )
    }

    return (
        <>
            <svg width="25" height="25" fill="#000" strokeWidth="0.2" stroke='#000' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M21.955 2.857a.625.625 0 0 0-.813-.812L2.96 9.319h-.002l-.565.225a.625.625 0 0 0-.102 1.108l.512.325.002.003 6.243 3.972 3.973 6.244.003.002.325.513a.625.625 0 0 0 1.107-.104l7.5-18.75ZM19.664 5.22l.588-1.473-1.472.589-9.368 9.367.423.27a.607.607 0 0 1 .192.192l.27.422 9.367-9.367Z" clipRule="evenodd"></path>
            </svg>
        </>
    )
}
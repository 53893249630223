import lottie from 'lottie-web'
import { defineElement } from 'lord-icon-element'
import { useState } from "react";

interface Props {
    size: string,
    fill: string,
    parentElem: string
}

export const AlertIcon = ({ size, fill, parentElem }: Props) => {

    defineElement(lottie.loadAnimation)

    return (
        <>
            <div className="alerticon">
                {/* @ts-ignore*/}
                <lord-icon style={{ width: `${size}`, height: `${size}` }} colors={`primary:${fill}`} trigger="click" target={`.${parentElem}`} src="https://cdn.lordicon.com/msetysan.json"></lord-icon>
            </div>
        </>
    )
}
import { CaretRightOutlined, SearchOutlined} from "@ant-design/icons";
import { BackArrowIcon, StatsIcon} from "../../components/icons";
import StatisticService from "../../services/Statistic-service";
import StatsService from "../../services/Statistic-service";
import React, {useEffect, useState} from "react";
import {IExtraStatusProps, IInvoiceStats, IUser} from "../../types/types";
import {click} from "../../hooks/useTelegram";
import {useNavigate} from "react-router-dom";
import {Collapse, DatePicker} from "antd";
import { Pie } from '@ant-design/plots';
import dayjs from "dayjs";
import './styles/style.css'

interface InvoicesStats {
    totalAmountRub: number,
    totalAmountUsd: number,
    totalAmountUsdt: number,
    totalInvoices: number,
    totalMonthInvoices: number,
    totalSemiAnnualInvoices: number,
    totalAnnualInvoices: number,
    stage1: {
        totalAmountRub: number,
        totalAmountUsd: number,
        totalAmountUsdt: number,
        totalInvoices: number,
        monthInvoices: number,
        semiAnnualInvoices: number,
        annualInvoices: number
    },
    stage2: {
        totalAmountRub: number,
        totalAmountUsd: number,
        totalAmountUsdt: number,
        totalInvoices: number,
        monthInvoices: number,
        semiAnnualInvoices: number,
        annualInvoices: number
    },
    stage3: {
        totalAmountRub: number,
        totalAmountUsd: number,
        totalAmountUsdt: number,
        totalInvoices: number,
        monthInvoices: number,
        semiAnnualInvoices: number,
        annualInvoices: number
    },
    stage4: {
        totalAmountRub: number,
        totalAmountUsd: number,
        totalAmountUsdt: number,
        totalInvoices: number,
        monthInvoices: number,
        semiAnnualInvoices: number,
        annualInvoices: number
    },
    stage5: {
        totalAmountRub: number,
        totalAmountUsd: number,
        totalAmountUsdt: number,
        totalInvoices: number,
        monthInvoices: number,
        semiAnnualInvoices: number,
        annualInvoices: number
    },
    stage6: {
        totalAmountRub: number,
        totalAmountUsd: number,
        totalAmountUsdt: number,
        totalInvoices: number,
        monthInvoices: number,
        semiAnnualInvoices: number,
        annualInvoices: number
    }
}

const StatisticsPage = () => {
    const [invoicesStats, setInvoicesStats] = useState<InvoicesStats>({} as InvoicesStats);
    const [stats, setStats] = useState<IInvoiceStats>({} as IInvoiceStats)
    const [data, setData] = useState<IExtraStatusProps[]>([])
    const navigate = useNavigate();

    const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 0.9,
        label: {
            type: 'inner',
            offset: '-30%',
            // content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
            content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
            style: {
                fontSize: 12,
                textAlign: 'center',
            },
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
    };

    // Стили для панелей
    const panelStyle = {
        marginBottom: 15,
        background: "#1C1C1E",
        borderRadius: "15px",
        border: 'none',
    };

    // Получение данных с сервера
    const getInvoices = async (startDate: string, endDate: string) => {
        const response = await StatisticService.getInvoices();

        const filteredInvoices = response.data.filter((invoice) => {
            const invoiceDateParts = invoice.date.split('/');
            const invoiceDate = new Date(`${invoiceDateParts[1]}/${invoiceDateParts[0]}/${invoiceDateParts[2]}`);

            const startDateParts = startDate.split('/');
            const startDateObj = new Date(`${startDateParts[1]}/${startDateParts[0]}/${startDateParts[2]}`);

            const endDateParts = endDate.split('/');
            const endDateObj = new Date(`${endDateParts[1]}/${endDateParts[0]}/${endDateParts[2]}`);

            return invoiceDate >= startDateObj && invoiceDate <= endDateObj && invoice.conversionPlace.includes("trial active")
        }); console.log(filteredInvoices.length)

        let invoicesStatsObj = {} as InvoicesStats;

        // stage 1 invoices
        const stage1Invoices = filteredInvoices.filter((invoice) => invoice.conversionPlace.includes("stage 1"));
        invoicesStatsObj.stage1 = {
            totalAmountRub: stage1Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "RUB") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalAmountUsd: stage1Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "USD") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalAmountUsdt: stage1Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "USDT") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalInvoices: stage1Invoices.length,
            monthInvoices: stage1Invoices.filter((invoice) => invoice.invoiceName.includes("month")).length,
            semiAnnualInvoices: stage1Invoices.filter((invoice) => invoice.invoiceName.includes("semi-annual")).length,
            annualInvoices: stage1Invoices.filter((invoice) => invoice.invoiceName.includes("annual")).length - stage1Invoices.filter((invoice) => invoice.invoiceName.includes("semi-annual")).length,
        }

        // stage 2 invoices
        const stage2Invoices = filteredInvoices.filter((invoice) => invoice.conversionPlace.includes("stage 2"));
        invoicesStatsObj.stage2 = {
            totalAmountRub: stage2Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "RUB") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalAmountUsd: stage2Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "USD") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalAmountUsdt: stage2Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "USDT") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalInvoices: stage2Invoices.length,
            monthInvoices: stage2Invoices.filter((invoice) => invoice.invoiceName.includes("month")).length,
            semiAnnualInvoices: stage2Invoices.filter((invoice) => invoice.invoiceName.includes("semi-annual")).length,
            annualInvoices: stage2Invoices.filter((invoice) => invoice.invoiceName.includes("annual")).length - stage1Invoices.filter((invoice) => invoice.invoiceName.includes("semi-annual")).length,
        }

        // stage 3 invoices
        const stage3Invoices = filteredInvoices.filter((invoice) => invoice.conversionPlace.includes("stage 3"));
        invoicesStatsObj.stage3 = {
            totalAmountRub: stage3Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "RUB") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalAmountUsd: stage3Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "USD") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalAmountUsdt: stage3Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "USDT") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalInvoices: stage3Invoices.length,
            monthInvoices: stage3Invoices.filter((invoice) => invoice.invoiceName.includes("month")).length,
            semiAnnualInvoices: stage3Invoices.filter((invoice) => invoice.invoiceName.includes("semi-annual")).length,
            annualInvoices: stage3Invoices.filter((invoice) => invoice.invoiceName.includes("annual")).length - stage1Invoices.filter((invoice) => invoice.invoiceName.includes("semi-annual")).length,
        }

        // stage 4 invoices
        const stage4Invoices = filteredInvoices.filter((invoice) => invoice.conversionPlace.includes("stage 4"));
        invoicesStatsObj.stage4 = {
            totalAmountRub: stage4Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "RUB") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalAmountUsd: stage4Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "USD") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalAmountUsdt: stage4Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "USDT") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalInvoices: stage4Invoices.length,
            monthInvoices: stage4Invoices.filter((invoice) => invoice.invoiceName.includes("month")).length,
            semiAnnualInvoices: stage4Invoices.filter((invoice) => invoice.invoiceName.includes("semi-annual")).length,
            annualInvoices: stage4Invoices.filter((invoice) => invoice.invoiceName.includes("annual")).length - stage1Invoices.filter((invoice) => invoice.invoiceName.includes("semi-annual")).length,
        }

        // stage 5 invoices
        const stage5Invoices = filteredInvoices.filter((invoice) => invoice.conversionPlace.includes("stage 5"));
        invoicesStatsObj.stage5 = {
            totalAmountRub: stage5Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "RUB") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalAmountUsd: stage5Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "USD") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalAmountUsdt: stage5Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "USDT") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalInvoices: stage5Invoices.length,
            monthInvoices: stage5Invoices.filter((invoice) => invoice.invoiceName.includes("month")).length,
            semiAnnualInvoices: stage5Invoices.filter((invoice) => invoice.invoiceName.includes("semi-annual")).length,
            annualInvoices: stage5Invoices.filter((invoice) => invoice.invoiceName.includes("annual")).length - stage1Invoices.filter((invoice) => invoice.invoiceName.includes("semi-annual")).length,
        }

        // stage 6 invoices
        const stage6Invoices = filteredInvoices.filter((invoice) => invoice.conversionPlace.includes("stage 6"));
        invoicesStatsObj.stage6 = {
            totalAmountRub: stage6Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "RUB") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalAmountUsd: stage6Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "USD") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalAmountUsdt: stage6Invoices.reduce((acc, invoice) => {
                if (invoice.currency === "USDT") {
                    return acc + invoice.totalAmount;
                }
                return acc;
            }, 0),
            totalInvoices: stage6Invoices.length,
            monthInvoices: stage6Invoices.filter((invoice) => invoice.invoiceName.includes("month")).length,
            semiAnnualInvoices: stage6Invoices.filter((invoice) => invoice.invoiceName.includes("semi-annual")).length,
            annualInvoices: stage6Invoices.filter((invoice) => invoice.invoiceName.includes("annual")).length - stage1Invoices.filter((invoice) => invoice.invoiceName.includes("semi-annual")).length,
        }

        // Общая статистика
        invoicesStatsObj.totalAmountRub = invoicesStatsObj.stage1.totalAmountRub + invoicesStatsObj.stage2.totalAmountRub + invoicesStatsObj.stage3.totalAmountRub + invoicesStatsObj.stage4.totalAmountRub + invoicesStatsObj.stage5.totalAmountRub + invoicesStatsObj.stage6.totalAmountRub;
        invoicesStatsObj.totalAmountUsd = invoicesStatsObj.stage1.totalAmountUsd + invoicesStatsObj.stage2.totalAmountUsd + invoicesStatsObj.stage3.totalAmountUsd + invoicesStatsObj.stage4.totalAmountUsd + invoicesStatsObj.stage5.totalAmountUsd + invoicesStatsObj.stage6.totalAmountUsd;
        invoicesStatsObj.totalAmountUsdt = invoicesStatsObj.stage1.totalAmountUsdt + invoicesStatsObj.stage2.totalAmountUsdt + invoicesStatsObj.stage3.totalAmountUsdt + invoicesStatsObj.stage4.totalAmountUsdt + invoicesStatsObj.stage5.totalAmountUsdt + invoicesStatsObj.stage6.totalAmountUsdt;
        invoicesStatsObj.totalInvoices = invoicesStatsObj.stage1.totalInvoices + invoicesStatsObj.stage2.totalInvoices + invoicesStatsObj.stage3.totalInvoices + invoicesStatsObj.stage4.totalInvoices + invoicesStatsObj.stage5.totalInvoices + invoicesStatsObj.stage6.totalInvoices;
        invoicesStatsObj.totalMonthInvoices = invoicesStatsObj.stage1.monthInvoices + invoicesStatsObj.stage2.monthInvoices + invoicesStatsObj.stage3.monthInvoices + invoicesStatsObj.stage4.monthInvoices + invoicesStatsObj.stage5.monthInvoices + invoicesStatsObj.stage6.monthInvoices;
        invoicesStatsObj.totalSemiAnnualInvoices = invoicesStatsObj.stage1.semiAnnualInvoices + invoicesStatsObj.stage2.semiAnnualInvoices + invoicesStatsObj.stage3.semiAnnualInvoices + invoicesStatsObj.stage4.semiAnnualInvoices + invoicesStatsObj.stage5.semiAnnualInvoices + invoicesStatsObj.stage6.semiAnnualInvoices;
        invoicesStatsObj.totalAnnualInvoices = invoicesStatsObj.stage1.annualInvoices + invoicesStatsObj.stage2.annualInvoices + invoicesStatsObj.stage3.annualInvoices + invoicesStatsObj.stage4.annualInvoices + invoicesStatsObj.stage5.annualInvoices + invoicesStatsObj.stage6.annualInvoices;

        setInvoicesStats(invoicesStatsObj);
    }

    // Выбор даты
    const chooseDate = async (res: any) => {
        const dateObject = dayjs(res[0]);
        const dateObject2 = dayjs(res[1]);

        const date = dateObject.format('DD/MM/YYYY');
        const date2 = dateObject2.format('DD/MM/YYYY');

        await getInvoices(date, date2);
    }

    // Получаем общую статистику
    const getStats = async () => {
        const response = await StatsService.getInvoiceStats()
        setStats(response.data as IInvoiceStats)
    }

    // Получаем юзеров
    const getUsersConversionToActive = async () => {
        const response = await StatsService.getUsersConversionToActive(); console.log(response.data)

        // Если в массиве обьект содержит value = 0, то удаляем его
        const filteredArr = response.data.filter((item: any) => item.value !== 0)

        setData(filteredArr)
    }

    useEffect(() => {
        getStats()
        getUsersConversionToActive()
    }, [])

    return (
        <>
            <div className="statistics_page">
                <div className="statistics_page_header">
                    <div className="statistics_page_header_backbutton">
                        <BackArrowIcon size={"18px"} onClick={() => { navigate("/"); click() }}/>
                        <span className="span-1231" onClick={() => { navigate("/"); click() }}>Назад</span>
                    </div>
                    <h1>Статистика <StatsIcon size={"24px"} fill={"#1d76ee"} parrentElem={"statistics_page_header"}/></h1>
                </div>
                {/*Статистика конверсий перехода из inactive -> active*/}
                <div className="statistics_page_inactive_to_active">
                    <h1 className="statistics_page_inactive_to_active_h1">Конверсии из inactive {"->"} active</h1>
                    <Pie {...config}/>
                </div>
                {/*Общая статистика*/}
                <div className="revenue">
                    {/*Общая прибыль*/}
                    <div className="revenue_field revenue_total">
                        <h1 className="revenue_field_h1">Общая выручка (RUB):</h1>
                        <span className="revenue_field_data">{stats?.totalAmount ? stats?.totalAmount : 0} ₽</span>
                    </div>
                    <div className="revenue_field revenue_total">
                        <h1 className="revenue_field_h1">Общая выручка (USD):</h1>
                        <span className="revenue_field_data">{stats?.totalAmountUSD ? stats?.totalAmountUSD : 0} $</span>
                    </div>
                    <div className="revenue_field revenue_total">
                        <h1 className="revenue_field_h1">Общая выручка (USDT):</h1>
                        <span className="revenue_field_data">{stats?.totalAmountUSDT ? stats?.totalAmountUSDT : 0} $</span>
                    </div>
                    {/*Общее кол-во покупок*/}
                    <div className="revenue_field revenue_totalSubsriberInvoices">
                        <h1 className="revenue_field_h1">Кол-во оформленных подписок:</h1>
                        <span className="revenue_field_data">{stats?.totalSubscriberInvoices ? stats?.totalSubscriberInvoices : 0}</span>
                    </div>
                    {/*Кол-во оформленных месячных подписок*/}
                    <div className="revenue_field revenue_monthlySubsriberInvoices">
                        <h1 className="revenue_field_h1">Кол-во оформленных месячных подписок:</h1>
                        <span className="revenue_field_data">{stats?.monthlySubscriberInvoices ? stats?.monthlySubscriberInvoices : 0}</span>
                    </div>
                    {/*Кол-во оформленных полу-годовых подписок*/}
                    <div className="revenue_field revenue_semiAnnualSubsriberInvoices">
                        <h1 className="revenue_field_h1">Кол-во оформленных полу-годовых подписок:</h1>
                        <span className="revenue_field_data">{stats?.semiAnnualSubscriberInvoices ? stats?.semiAnnualSubscriberInvoices : 0}</span>
                    </div>
                    {/*Кол-во оформленных годовых подписок*/}
                    <div className="revenue_field revenue_yearlySubsriberInvoices">
                        <h1 className="revenue_field_h1">Кол-во оформленных годовых подписок:</h1>
                        <span className="revenue_field_data">{stats?.yearlySubscriberInvoices ? stats?.yearlySubscriberInvoices : 0}</span>
                    </div>
                </div>
                {/*Статистика по подпискам по периодам*/}
                <h1 className="statistics_page_title">Выберите период <SearchOutlined/></h1>
                <DatePicker.RangePicker className="statistics_page_rangePicker" onChange={async (res) => await chooseDate(res)}/>
                <div className="statistics_page_totalPeriodStats">
                    <div className="statistics_page_totalPeriodStats_field">
                        <h1 className="statistics_page_totalPeriodStats_field_h1">Общая выручка (RUB):</h1>
                        <span className="statistics_page_totalPeriodStats_field_data">{invoicesStats?.totalAmountRub} ₽</span>
                    </div>
                    <div className="statistics_page_totalPeriodStats_field">
                        <h1 className="statistics_page_totalPeriodStats_field_h1">Общая выручка (USD):</h1>
                        <span className="statistics_page_totalPeriodStats_field_data">{invoicesStats?.totalAmountUsd} $</span>
                    </div>
                    <div className="statistics_page_totalPeriodStats_field">
                        <h1 className="statistics_page_totalPeriodStats_field_h1">Общая выручка (USDT):</h1>
                        <span className="statistics_page_totalPeriodStats_field_data">{invoicesStats?.totalAmountUsdt} $</span>
                    </div>
                    <div className="statistics_page_totalPeriodStats_field">
                        <h1 className="statistics_page_totalPeriodStats_field_h1">Общее кол-во подписок:</h1>
                        <span className="statistics_page_totalPeriodStats_field_data">x{invoicesStats?.totalInvoices}</span>
                    </div>
                    <div className="statistics_page_totalPeriodStats_field">
                        <h1 className="statistics_page_totalPeriodStats_field_h1">Месячных подписок:</h1>
                        <span className="statistics_page_totalPeriodStats_field_data">x{invoicesStats?.totalMonthInvoices}</span>
                    </div>
                    <div className="statistics_page_totalPeriodStats_field">
                        <h1 className="statistics_page_totalPeriodStats_field_h1">Полу-годовых подписок:</h1>
                        <span className="statistics_page_totalPeriodStats_field_data">x{invoicesStats?.totalSemiAnnualInvoices}</span>
                    </div>
                    <div className="statistics_page_totalPeriodStats_field">
                        <h1 className="statistics_page_totalPeriodStats_field_h1">Годовых подписок:</h1>
                        <span className="statistics_page_totalPeriodStats_field_data">x{invoicesStats?.totalAnnualInvoices}</span>
                    </div>
                </div>
                <Collapse bordered={false} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} style={{ background: "none", color: "#1C1C1E" }} className="statistics_page_collapse">
                    <Collapse.Panel header="Stage 1" key="1" style={panelStyle}>
                        <p className="collapse_panel_p">Выручка в RUB: <div>{invoicesStats?.stage1?.totalAmountRub} ₽</div></p>
                        <p className="collapse_panel_p">Выручка в USD: <div>{invoicesStats?.stage1?.totalAmountUsd} $</div></p>
                        <p className="collapse_panel_p">Выручка в USDT: <div>{invoicesStats?.stage1?.totalAmountUsdt} $</div></p>
                        <br/>
                        <p className="collapse_panel_p">Общее кол-во подписок: <div>x{invoicesStats?.stage1?.totalInvoices}</div></p>
                        <p className="collapse_panel_p">Месячных подписок: <div>x{invoicesStats?.stage1?.monthInvoices}</div></p>
                        <p className="collapse_panel_p">Полугодовых подписок: <div>x{invoicesStats?.stage1?.semiAnnualInvoices}</div></p>
                        <p className="collapse_panel_p">Годовых подписок: <div>x{invoicesStats?.stage1?.annualInvoices}</div></p>
                    </Collapse.Panel>
                    <Collapse.Panel header="Stage 2" key="2" style={panelStyle}>
                        <p className="collapse_panel_p">Выручка в RUB: <div>{invoicesStats?.stage2?.totalAmountRub} ₽</div></p>
                        <p className="collapse_panel_p">Выручка в USD: <div>{invoicesStats?.stage2?.totalAmountUsd} $</div></p>
                        <p className="collapse_panel_p">Выручка в USDT: <div>{invoicesStats?.stage2?.totalAmountUsdt} $</div></p>
                        <br/>
                        <p className="collapse_panel_p">Общее кол-во подписок: <div>x{invoicesStats?.stage2?.totalInvoices}</div></p>
                        <p className="collapse_panel_p">Месячных подписок: <div>x{invoicesStats?.stage2?.monthInvoices}</div></p>
                        <p className="collapse_panel_p">Полугодовых подписок: <div>x{invoicesStats?.stage2?.semiAnnualInvoices}</div></p>
                        <p className="collapse_panel_p">Годовых подписок: <div>x{invoicesStats?.stage2?.annualInvoices}</div></p>
                    </Collapse.Panel>
                    <Collapse.Panel header="Stage 3" key="3" style={panelStyle}>
                        <p className="collapse_panel_p">Выручка в RUB: <div>{invoicesStats?.stage3?.totalAmountRub} ₽</div></p>
                        <p className="collapse_panel_p">Выручка в USD: <div>{invoicesStats?.stage3?.totalAmountUsd} $</div></p>
                        <p className="collapse_panel_p">Выручка в USDT: <div>{invoicesStats?.stage3?.totalAmountUsdt} $</div></p>
                        <br/>
                        <p className="collapse_panel_p">Общее кол-во подписок: <div>x{invoicesStats?.stage3?.totalInvoices}</div></p>
                        <p className="collapse_panel_p">Месячных подписок: <div>x{invoicesStats?.stage3?.monthInvoices}</div></p>
                        <p className="collapse_panel_p">Полугодовых подписок: <div>x{invoicesStats?.stage3?.semiAnnualInvoices}</div></p>
                        <p className="collapse_panel_p">Годовых подписок: <div>x{invoicesStats?.stage3?.annualInvoices}</div></p>
                    </Collapse.Panel>
                    <Collapse.Panel header="Stage 4" key="4" style={panelStyle}>
                        <p className="collapse_panel_p">Выручка в RUB: <div>{invoicesStats?.stage4?.totalAmountRub} ₽</div></p>
                        <p className="collapse_panel_p">Выручка в USD: <div>{invoicesStats?.stage4?.totalAmountUsd} $</div></p>
                        <p className="collapse_panel_p">Выручка в USDT: <div>{invoicesStats?.stage4?.totalAmountUsdt} $</div></p>
                        <br/>
                        <p className="collapse_panel_p">Общее кол-во подписок: <div>x{invoicesStats?.stage4?.totalInvoices}</div></p>
                        <p className="collapse_panel_p">Месячных подписок: <div>x{invoicesStats?.stage4?.monthInvoices}</div></p>
                        <p className="collapse_panel_p">Полугодовых подписок: <div>x{invoicesStats?.stage4?.semiAnnualInvoices}</div></p>
                        <p className="collapse_panel_p">Годовых подписок: <div>x{invoicesStats?.stage4?.annualInvoices}</div></p>
                    </Collapse.Panel>
                    <Collapse.Panel header="Stage 5" key="5" style={panelStyle}>
                        <p className="collapse_panel_p">Выручка в RUB: <div>{invoicesStats?.stage5?.totalAmountRub} ₽</div></p>
                        <p className="collapse_panel_p">Выручка в USD: <div>{invoicesStats?.stage5?.totalAmountUsd} $</div></p>
                        <p className="collapse_panel_p">Выручка в USDT: <div>{invoicesStats?.stage5?.totalAmountUsdt} $</div></p>
                        <br/>
                        <p className="collapse_panel_p">Общее кол-во подписок: <div>x{invoicesStats?.stage5?.totalInvoices}</div></p>
                        <p className="collapse_panel_p">Месячных подписок: <div>x{invoicesStats?.stage5?.monthInvoices}</div></p>
                        <p className="collapse_panel_p">Полугодовых подписок: <div>x{invoicesStats?.stage5?.semiAnnualInvoices}</div></p>
                        <p className="collapse_panel_p">Годовых подписок: <div>x{invoicesStats?.stage5?.annualInvoices}</div></p>
                    </Collapse.Panel>
                    <Collapse.Panel header="Stage 6" key="6" style={panelStyle}>
                        <p className="collapse_panel_p">Выручка в RUB: <div>{invoicesStats?.stage6?.totalAmountRub} ₽</div></p>
                        <p className="collapse_panel_p">Выручка в USD: <div>{invoicesStats?.stage6?.totalAmountUsd} $</div></p>
                        <p className="collapse_panel_p">Выручка в USDT: <div>{invoicesStats?.stage6?.totalAmountUsdt} $</div></p>
                        <br/>
                        <p className="collapse_panel_p">Общее кол-во подписок: <div>x{invoicesStats?.stage6?.totalInvoices}</div></p>
                        <p className="collapse_panel_p">Месячных подписок: <div>x{invoicesStats?.stage6?.monthInvoices}</div></p>
                        <p className="collapse_panel_p">Полугодовых подписок: <div>x{invoicesStats?.stage6?.semiAnnualInvoices}</div></p>
                        <p className="collapse_panel_p">Годовых подписок: <div>x{invoicesStats?.stage6?.annualInvoices}</div></p>
                    </Collapse.Panel>
                </Collapse>
            </div>
        </>
    )
}

export default StatisticsPage
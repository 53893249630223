import {CreateProducerModal} from "../../pages/Dashboard/modals";
import {useState} from "react";
import "./styles/style.css"
import {ArrowRightOutlined} from "@ant-design/icons";
import {UsersIcon} from "../icons";
import {click} from "../../hooks/useTelegram";


export const CreateProducer = () => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <CreateProducerModal open={open} setOpen={setOpen}/>
            <div className="createProduserWidget" onClick={() => { setOpen(true); click() }}>
                <h1 className="createProduserWidget_h1">
                    <UsersIcon size={"24px"} fill={"#fff"}/>
                    <span>Назначить продюсера</span>
                </h1>
                <ArrowRightOutlined/>
            </div>
        </>
    )
}
import AlertsService from "../../services/Alert-service";
import React, {FC, useContext, useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { AdsComponent } from "../../components";
import {click, tg} from "../../hooks/useTelegram";
import {useNavigate} from "react-router-dom";
import { Context } from "../../index";
import './styles/style.css'
import {IAds} from "../../types/types";

const Ads: FC = () => {
    const [adsOrders, setAdsOrders] = React.useState([])
    const navigate = useNavigate()
    const { store } = useContext(Context)
    const addAds = async () => {
        await AlertsService.createAds(store.username)
        tg.close(); click()
    }

    useEffect(() => {
        const getAdsOrders = async () => {
            const response = await AlertsService.getAds()
            setAdsOrders(response.data ? response.data : [])
        }
        getAdsOrders()
    }, [store])

    return (
        <>
            <div className="ads_page">
                <div className="ads_header">
                    <div className="ads_header_backbutton">
                        <ArrowLeftOutlined onClick={() => { navigate("/"); click() }}/>
                        <span className="span" onClick={() => { navigate("/"); click() }}>Назад</span>
                        <span className="span2" onClick={addAds}>+</span>
                    </div>
                    <div className="ads_header_title">Рекламные ордера</div>
                </div>
                <div className="ads_list">
                    {adsOrders
                        ?
                        <>{adsOrders.map((ads: IAds, index) => <AdsComponent key={index} {...ads}/>)}</>
                        :
                        <>Пока что нету рекламодателей</>
                    }
                </div>
            </div>
        </>
    )
}

export default Ads
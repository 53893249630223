import BotSettingsService from "../services/BotSettings-service";
import ApiKeysService from '../services/ApiKeys-service'
import UserService from '../services/User-service'
import { makeAutoObservable } from 'mobx'
import {IUser} from "../types/types";

export default class Store {
    isLoading = false
    username = window.Telegram.WebApp?.initDataUnsafe?.user?.username
    // username = "fullstackdevpitt"
    user = {} as IUser

    constructor() {
        makeAutoObservable(this);
    };

    setLoading(type: boolean) {
        this.isLoading = type
    };
    setUsername(type: string) {
        this.username = type
    }
    setUser(type: IUser) {
        this.user = type
    }

    async getUser() {
        this.isLoading = true
        try {
            const user = await UserService.getUser(this.username)
            this.setUser(user.data)
            return user.data
        } catch (error) {
            console.log({ code: error.code, message: error.message, errorPlace: 'store.ts => getUser()' })
        } finally {
            this.isLoading = false
        }
    }
    async sendApiKeys(keys: string) {
        this.isLoading = true
        try {
            return await ApiKeysService.sendApiKeys(keys)
        } catch (error) {
            console.log(error)
        } finally {
            this.isLoading = false
        }
    }
    async editServiceStatus() {
        this.isLoading = true
        try {
            return await BotSettingsService.editServiceStatus()
        } catch (error) {
            console.log(error)
        } finally {
            this.isLoading = false
        }
    }
    async getServiceStatus() {
        this.isLoading = true
        try {
            const response = await BotSettingsService.getServiceStatus()
            return response.data
        } catch (error) {
            console.log(error)
        } finally {
            this.isLoading = false
        }
    }
}
import axios from "axios";

export default class AlertService {
    static async serviceAlert() {
        try {
            return await axios.post(`https://www.ai-in-telegram-control-panel-bot-backend.ru/app2/servicealert`)
        } catch (error) {
            console.log({ code: 500, message: error, errorPlace: 'AlertService.ts => serviceAlert()' })
        }
    }
    static async createAds(username: string) {
        try {
            return await axios.post(`https://www.ai-in-telegram-control-panel-bot-backend.ru/app1/createads`, { username })
        } catch (error) {
            console.log({ code: 500, message: error, errorPlace: 'AlertService.ts => createAds()' })
        }
    }
    static async getAds() {
        try {
            return await axios.post(`https://www.ai-in-telegram-control-panel-bot-backend.ru/app1/getads`)
        } catch (error) {
            console.log({ code: 500, message: error, errorPlace: 'AlertService.ts => getAds()' })
        }
    }
}
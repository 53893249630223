import { BackendUrl } from "../config/config";
import axios, {AxiosResponse} from 'axios'
import {IUser} from "../types/types";
export default class UserService {
    static async getUsersCount() {
        try {
            return await axios.post(`${BackendUrl}/getuserscount`)
        } catch (error) {
            const err = { status: 500, error: error, errorplace: "User-service.ts => getUsersCount()" }
            console.log(err)
        }
    }
    static async getUser(username: string): Promise<AxiosResponse<IUser>> {
        try {
            return await axios.post<IUser>(`https://www.ai-in-telegram-control-panel-bot-backend.ru/app2/getuser`, { username })
        } catch (error) {
            console.log({ status: 500, error: error, errorplace: "User-service.ts => getUser()" })
        }
    }
}
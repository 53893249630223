import lottie from 'lottie-web'
import { defineElement } from 'lord-icon-element'
import { useState } from "react";

interface Props {
    size: string,
    fill: string,
    parrentElem: string
}

export const DoneIcon = ({ size, fill, parrentElem }: Props) => {

    defineElement(lottie.loadAnimation)

    return (
        <>
            <div className="doneicon">
                {/* @ts-ignore*/}
                <lord-icon style={{ width: `${size}`, height: `${size}` }} colors={`primary:${fill}`} trigger="click" target={`.${parrentElem}`} src="https://cdn.lordicon.com/yqzmiobz.json"></lord-icon>
            </div>
        </>
    )
}
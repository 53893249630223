import React, {FC, useEffect, useState} from "react";
import UserService from "../../services/User-service";
import { Widget } from "../widget/widget";
import './styles/style.css'
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";

export const AllUsersCountWidget = () => {
    const [usersCount, setUsersCount] = useState<number>(0)
    const [differenceCount, setDifferenceCount] = useState<number>(0)
    const [differencePercent, setDifferencePercent] = useState<number>(0)

    const getUsersCount = async () => {
        const response = await UserService.getUsersCount()
        if (response?.data?.status === 403) return alert("Не удалось получить кол-во пользователей")
        const count = response?.data?.count
        const differenceCount = response?.data?.statistic?.differenceCount
        const differencePercent = response?.data?.statistic?.differencePercent
        setDifferenceCount(differenceCount)
        setDifferencePercent(differencePercent)
        console.log(response.data)
        return setUsersCount(count)
    }

    useEffect(() => {
        getUsersCount()
    }, [])

    return (
        <>
            <div className="widgetCard">
                <div className="title">Общее кол-во пользователей</div>
                <div className="stats" style={{ fontSize: "35px" }}>{usersCount}</div>
                <div className={differenceCount > 0 ? "difference" : "difference_null"}>
                    {differenceCount > 0
                        ?
                        <><ArrowUpOutlined/> {differenceCount} • {differencePercent + " %"}</>
                        :
                        <>{differencePercent}{" %"} за сегодня</>}
                </div>
            </div>
        </>
    )
}
